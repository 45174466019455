import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function EstadoCuentaCXP() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [proveedores, setProveedores] = useState([]);
 
  const [mailTo, setMailTo] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
 
  const [nombreProveedor, setNombreProveedor] = useState([]);
  const [saldo, setSaldo] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);
  const [modalMail3, setModalMail3] = useState(false);
  const toggleMail3 = () => setModalMail3(!modalMail3);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalAgrupado, setModalAgrupado] = useState(false);
  const toggleAgrupado = () => setModalAgrupado(!modalAgrupado);

  const [activo, setActivo] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [grupo, setGrupo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [movimientos, setMovimientos] = useState([]);

  const [idProveedor, setIdProveedor] = useState("");
  const [saldoInicial, setSaldoInicial] = useState(0);
  const [plazo, setPlazo] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_PROVEEDORES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        // Array para pagination
        let arrayTabla = allProveedores
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre_comercial: a.nombre_comercial,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
              };
          
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setProveedores(allProveedores);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  

    let SI = movimientos
      .map((c) => {
        if (selectedFechaInicio) {
          if (
            (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ) {
            return c.cargo - c.abono;
          }
        } else {
          return 0;
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let TC = SI.reduce((t, total, index) => t + total, 0);

    setSaldoInicial(TC);
  }, [user, movimientos, selectedFechaInicio]);

  const ListaProveedores = comments.sort((a, b) =>
    a.nombre_comercial > b.nombre_comercial ? 1 : -1
  );

  function PDFTabla() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = ListaProveedores.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [a.nombre_comercial,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta Proveedores`, 15, 40);
    doc.autoTable({
      head: [
        [
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuentaProv.pdf`);
  }

  function excel() {
    const dataExcel = ListaProveedores.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Proveedor: a.nombre_comercial,
          Cargos: a.cargos,
          Abonos: a.abonos,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuentaProv";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuentaProv",
        sheetFilter: [
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = ListaProveedores.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [a.nombre_comercial,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta Proveedores`, 15, 40);
    doc.autoTable({
      head: [
        [
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los proveedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search) ||
          comment.abonos.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
  
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cargos" &&
      sorting.field != "abonos" &&
      sorting.field != "saldo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

  async function jalaMov(id, proveedor, saldo) {
    setMovimientos([]);
    await axios
      .get(`${URL_ESTADO_CUENTA}CXP/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setSelectedFechaInicio("")
    setNombreProveedor(proveedor);
    setIdProveedor(id);
    setSaldo(saldo);
    toggle();
  }

  async function jalaMovRel(id, proveedor, saldo) {
    setMovimientos([]);
    await axios
      .get(`${URL_ESTADO_CUENTA}CXPRelacionado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setSelectedFechaInicio("")
    setNombreProveedor(proveedor);
    setIdProveedor(id);
    setSaldo(saldo);
    toggle();
  }

  function PDFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.factura_proveedor,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 145, 20, 45, 25);
    doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 15);
    doc.text(`Proveedor: ${nombreProveedor}`, 15, 22);
    doc.text(`Desde: ${selectedFechaInicio}`, 15, 29);
    doc.text(`Saldo Anterior: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial)}`, 15, 36);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Fact. Prov.",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaProveedor-${nombreProveedor}.pdf`);
  }

  function emailFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.factura_proveedor,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 150, 23, 35, 25);
    doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 15);
    doc.text(`Proveedor: ${nombreProveedor}`, 15, 22);
    doc.text(`Desde: ${selectedFechaInicio}`, 15, 29);
    doc.text(`Saldo Anterior: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial)}`, 15, 36);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Fact. Prov.",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaProveedor-${nombreProveedor}.pdf`);
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreProveedor}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreProveedor}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          FactProv: a.factura_proveedor,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaProveedor-${nombreProveedor}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuentaProv",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "FactProv",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "FactProv",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  
  let totalCargosTabla = 0;
  let totalAbonosTabla = 0;
  let totalSaldoTabla = 0;

  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const secondDate = new Date(hoy);
  let saldoVencido =0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCXP"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <h3 align="center">Cuentas Por Pagar</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
           
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalCargosTabla = totalCargosTabla + parseFloat(p.cargos);
                totalAbonosTabla = totalAbonosTabla + parseFloat(p.abonos);
                totalSaldoTabla = totalSaldoTabla + parseFloat(p.saldo);
                {
                  return (
                    <tr>
                      <td> {p.nombre_comercial}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(p.cargos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(p.abonos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(p.saldo)}
                      </td>
                      <td>
                        <Button
                          color="info"
                          size="sm"
                          onClick={(e) =>
                            jalaMov(p._id, p.nombre_comercial, p.saldo)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>

                        {/* <Button
                          color="danger"
                          size="sm"
                          onClick={(e) =>
                            jalaMovRel(p._id, p.nombre_comercial, p.saldo)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button> */}

                        {/* <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            jalaMovAgrupado(p._id, p.proveedor, p.plazo, p.saldo)
                          }
                          id="Detalle"
                        >
                          <i class="far fa-newspaper"></i>
                        </Button> */}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td className="negrita" align="right">
                  Totales
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreProveedor}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

          

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  {/* <th className="tituloTabla">Factura</th> */}
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="right">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(0)}</td>
                    {/* <td align="right">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(0)}</td> */}
                    <td align="right">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                      return (
                        <tr>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          {/* <td align="center">{c.factura_proveedor}</td> */}
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
                                saldoParcialTabla
                              )}
                          </td>
                        </tr>
                      )
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



       <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
        <ModalHeader toggle={toggleMail2}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOC}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default EstadoCuentaCXP;
