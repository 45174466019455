import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);

  const [articulos, setArticulos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedStatus, setSelectedStatus] = useState("");
  
  const [programacion, setProgramacion] = useState("");
  const [programacionPedido, setProgramacionPedido] = useState("");
  const [selectedOrdenesCompra, setSelectedOrdenesCompra] = useState("");
  const [ordenesCompra, setOrdenesCompra] = useState([]);

  const [fecha, setFecha] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [programacionEdit, setProgramacionEdit] = useState("");
  const [cantSurtido, setCantSurtido] = useState(0);


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      unidad: 0,
      colores: "",
      arrayColoresArticulo: [],
      cantidad: 0,
      surtido: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      clave:""
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
        .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            if(a.programacion == "Si"){
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                fecha_compromiso: a.fecha_compromiso,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].codigo + " / " + a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_kilos: a.total_kilos,
                total_metros: a.total_metros,
                status: a.status,
                autorizado: a.autorizado,
                cantidad: a.total_kilos + a.total_metros,
                surtido: a.surtido,
                pendiente_surtir: a.pendiente_surtir,
                programacion: a.programacion,
                ordenCompra: a.ordenesCompra[0]._id
            }
            
            }else{
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                fecha_compromiso: a.fecha_compromiso,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].codigo + " / " + a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_kilos: a.total_kilos,
                total_metros: a.total_metros,
                status: a.status,
                autorizado: a.autorizado,
                cantidad: a.total_kilos + a.total_metros,
                surtido: a.surtido,
                pendiente_surtir: a.pendiente_surtir,
                programacion: a.programacion,
                ordenCompra: ""
            }
          }
          })
          .filter(function (el) {
            return el != null;
          });

          let data = Object.values(arrayTabla);

          let agrupado = data.reduce(function (groups, item) {
            const val = item["_id"]
            groups[val] = groups[val] || {
              _id: item._id,
              surtido: 0,
              pendiente_surtir: 0,
              cantidad:0
            };
            groups[val].surtido += item.surtido;
            groups[val].pendiente_surtir += item.pendiente_surtir;
            groups[val].cantidad += item.cantidad;
            groups[val]._id = item._id;
            groups[val].numero = item.numero;
            groups[val].activo = item.activo;
            groups[val].fecha = item.fecha;
            groups[val].colaboradores = item.colaboradores;
            groups[val].idColaboradores = item.idColaboradores;
            groups[val].fecha_compromiso = item.fecha_compromiso;
            groups[val].total_general = item.total_general;
            groups[val].clientes = item.clientes;
            groups[val].idClientes = item.idClientes;
            groups[val].status = item.status;
            groups[val].autorizado = item.autorizado;
            groups[val].observaciones = item.observaciones;
            groups[val].programacion = item.programacion;
            groups[val].ordenCompra = item.ordenCompra;
           
            return groups;
          },[])

          let dataFinal = Object.values(agrupado);

        setPedidos(dataFinal);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_ORDENES_COMPRA}Tipo/Internacional`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allOrdenes = response.data;
          setOrdenesCompra(allOrdenes);
    
      })
      .catch((err) => {
        console.log(err);
      });
},[selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.fecha_compromiso,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Fecha Compromiso",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Compromiso: a.fecha_compromiso,
          Cliente: a.clientes,
          Kilos: a.total_kilos,
          Metros: a.total_metros,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Compromiso",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Compromiso",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.fecha_compromiso,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
        if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Fecha Compromiso",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    fecha_compromiso,
    idClientes,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero_pedido,
    programacion,
    idEdit,
    cantSurtido
  ) {
    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setFechaCompromiso(fecha_compromiso);
    setSelectedClienteEdit(idClientes);
    setTotalUnidaKilos(total_kilos);
    setTotalUnidaMetros(total_metros);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setProgramacionEdit(programacion)
    setIdEdit(idEdit);
    setCantSurtido(cantSurtido);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    colaboradores,
    fecha_compromiso,
    idClientes,
    clientes,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0].name,
              clave: a.clave,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              descuento: a.descuento,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              clave: a.clave
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.unidad,
            a.colores,
            a.clave,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.surtido),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            a.descuento + " %",
            "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
            if(user.empresa =="Shaltex"){
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    }else{  
    img2.src = process.env.PUBLIC_URL + "logoInstitucionMezclilla.png";
    }
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Pedido # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Cliente ${clientes}`, 20, 30);
        doc.text(
          `Vendedor ${colaboradores}, días Compromiso ${fecha_compromiso}`,
          20,
          35
        );
        doc.text(
          `Metros ${new Intl.NumberFormat("en-US").format(
            TM
          )} / Kilos ${new Intl.NumberFormat("en-US").format(TK)}`,
          20,
          40
        );
        doc.setFont(undefined, "bold");
        doc.text(`Observaciones ${observaciones}`, 20, 45);
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Unidad",
              "Color",
              "Clave",
              "Cantidad",
              "Surtido",
              "Precio",
              "Descuento",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          startY: 50,
          foot: [
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_PEDIDOS_EDIT,
          {
            fecha,
            colaboradores: selectedColaboradorEdit,
            fecha_compromiso,
            clientes: selectedClienteEdit,
            total_kilos,
            total_metros,
            total_general,
            observaciones,
            programacion: programacionEdit
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Pedido",
            detalle: `${numero_pedido} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Observaciones", field: "observaciones", sortable: false },
    { name: "Fecha Compromiso", field: "fecha_compromiso", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Surtido", field: "surtido", sortable: true },
    { name: "Pend Surtir", field: "pendiente_surtir", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase())||
          comment.cantidad.toString().includes(search)||
          comment.surtido.toString().includes(search)||
          comment.pendiente_surtir.toString().includes(search)||
          comment.total_general.toString().includes(search)||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }


    if (selectedStatus) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(selectedStatus)
      );
    }

    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }
    if (programacion) {
      computedComments = computedComments.filter((e) =>
        e.programacion.includes(programacion)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "cantidad" && sorting.field != "surtido" && sorting.field != "pendiente_surtir" && sorting.field != "total_general" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "cantidad" || sorting.field == "surtido" || sorting.field == "pendiente_surtir" || sorting.field == "total_general" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "cantidad" || sorting.field == "surtido" || sorting.field == "pendiente_surtir" || sorting.field == "total_general" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    // activo,
    programacion,
    selectedFechaInicio,
    selectedFechaFin,
    selectedStatus
  ]);

  function EditArticulos(idOC, numero_pedido, programacion, ordenCompra) {
    setProgramacionPedido(programacion);
    setSelectedOrdenesCompra(ordenCompra);
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        unidad: 0,
        colores: "",
        arrayColoresArticulo: [],
        cantidad: 0,
        surtido: 0,
        precio: 0,
        descuento: 0,
        total: 0,
        clave:""
      },
    ]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              arrayColoresArticulo: a.articulos[0].colores,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              descuento: a.descuento,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              clave:a.clave
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        handleChangeInputExistentesArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo
        );
      }
    });
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length-1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: "",
        arrayColoresArticulo:ultimo.arrayColoresArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        clave:""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = values.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = values.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        handleChangeInputArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: total_general_edit,
            total_kilos: total_kilos_edit,
            total_metros: total_metros_edit,
            pendiente_surtir: total_kilos_edit + total_metros_edit - cantSurtido,
            programacion: programacionPedido,
            ...(programacionPedido == "Si" ? { ordenesCompra: [selectedOrdenesCompra] } : { ordenesCompra: [] })
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.colores != "" && a.cantidad !=0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    colores: a.colores,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    clave: a.clave,
                    ...(programacionPedido == "Si" ? { ordenesCompra: [selectedOrdenesCompra] } : { ordenesCompra: [] })
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  colores: a.colores,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  descuento: parseFloat(a.descuento),
                  total: parseFloat(a.total),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  clave:a.clave,
                  ...(programacionPedido == "Si" ? { ordenesCompra: [selectedOrdenesCompra] } : { ordenesCompra: [] })
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function abierto(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Abrirá!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Abrir!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Abierto",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
            .patch(
              `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
              {
                is_active: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              const newComments = comments.map((i) => {
                if (id === i._id) {
                  i.status = "Abierto"
                }
                return i;
              });
              setComments(newComments);

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Se Abrió",
                showConfirmButton: false,
                timer: 2000,
              });
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function cerrar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Cerrará!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
            .patch(
              `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
              {
                is_active: "No",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              const newComments = comments.map((i) => {
                if (id === i._id) {
                  i.status = "Cerrado"
                }
                return i;
              });
              setComments(newComments);

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Se Cerró",
                showConfirmButton: false,
                timer: 2000,
              });

              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

console.log("hola", user)

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.pedidos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/PedidosCreate"
                >
                  Nuevo Pedido
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Pedido
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pedidos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pedidos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Abierto">Abiertos</option>
                <option value="Cerrado">Cerrados</option>
              </Input>
            </Col>

            <Col md={2}>
              <Label>Programacion</Label>
              <Input
                bsSize="sm"
                type="select"
                value={programacion}
                onChange={(e) => {
                  setProgramacion(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>

          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      {/* <td>{a.activo}</td> */}
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.fecha_compromiso}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.surtido)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.total_general
                          )}{" "}
                      </td>
                      <td>
                        {user.pedidos_create ? (
                          <div>

                          <AutorizadoMultiple
                              idStatus={a._id}
                              autorizado={a.autorizado}
                              URL_AUTORIZADO={process.env.REACT_APP_URL_PEDIDOS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_PEDIDO}/autorizado`}
                            />

                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.colaboradores,
                                  a.fecha_compromiso,
                                  a.idClientes,
                                  a.clientes,
                                  a.total_kilos,
                                  a.total_metros,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>

                            {/* <BajaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_PEDIDOS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_PEDIDO}/baja`}
                            /> */}
                            {/* <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={process.env.REACT_APP_URL_PEDIDOS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_PEDIDO}/baja`}
                            /> */}
                             {a.status == "Abierto" ? (
                                <span>
                                  <Button
                                    color="primary"
                                    id="Cerrar"
                                    onClick={(e) =>cerrar(a._id)}
                                    size="sm"
                                  >
                                  <i class="fas fa-lock-open"></i>
                                  </Button>
                                </span>
                              ) : (
                                <span>
                                  <Button color="danger" onClick={(e) =>abierto(a._id)} size="sm">
                                    <i class="fas fa-lock"></i>
                                  </Button>
                                </span>
                              )}
                          </div>
                        ) : undefined}
                      </td>
                      <td>
                        {a.status == "Abierto" && a.activo == "Si" ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.idColaboradores,
                                  a.fecha_compromiso,
                                  a.idClientes,
                                  a.total_kilos,
                                  a.total_metros,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a.programacion,
                                  a._id,
                                  a.surtido,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditArticulos(a._id, a.numero, a.programacion, a.ordenCompra)}
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClienteEdit}
                required
                onChange={(e) => {
                  setSelectedClienteEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un Cliente</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Vendedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColaboradorEdit}
                required
                onChange={(e) => {
                  setSelectedColaboradorEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Fecha Compromiso</Label>
              <Input
                type="date"
                placeholder="Fecha Compromiso"
                value={fecha_compromiso}
                required
                onChange={(e) => {
                  setFechaCompromiso(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col> 
            {/* <Col md={2}>
              <Label>Programacion</Label>
              <Input
                bsSize="sm"
                type="select"
                value={programacionEdit}
                required
                onChange={(e) => {
                  setProgramacionEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Pedido {numero_pedido}</h4>
          <h4>
            TOTALES {total_kilos_edit} kg. / {total_metros_edit} mts. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={savePedido}>
            <Row>
            <Col md={3}>
              <Label>Programacion</Label>
              <Input
                bsSize="sm"
                type="select"
                value={programacionPedido}
                required
                onChange={(e) => {
                  setProgramacionPedido(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
              {programacionPedido == "Si"?(
                <>
                <Col md={3}>
                  <Label>Orden de Compra</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedOrdenesCompra}
                    required
                    onChange={(e) => {
                      setSelectedOrdenesCompra(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {ordenesCompra
                  .sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))
                  .map((a)=>{
                      if(a.is_active == "Si"){
                      return <option value={a._id}>{a.idOrdenCompra}</option>
                    }
                    })}
                  </Input>
            </Col>
                  </>
              ):undefined}
            </Row>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Colores</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Clave</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Surtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Descuento</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Activo</Label>
              </Col>
            </Row>

            {articulosPedido.map((ao) => (
              <div key={ao.id}>
                <Row>
                  {ao.surtido == 0 ? (
                    <Col md={2}>
                      <Input
                        name="articulos"
                        type="select"
                        value={ao.articulos}
                        required
                        onChange={(event) => {
                          BuscaArticuloExistentes(ao.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {articulos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  ) : (
                    <Col md={2}>
                      <Input
                        name="articulos"
                        type="select"
                        value={ao.articulos}
                        required
                        onChange={(event) => {
                          BuscaArticuloExistentes(ao.id, event);
                        }}
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {articulos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  )}

                  {ao.surtido == 0 ? (
                    <>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={ao.colores}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      >
                        <option value="">Selecciona</option>
                        {ao.arrayColoresArticulo
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="clave"
                      type="text"
                      placeholder="Clave"
                      value={ao.clave}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                    </Col>

                    <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantidad"
                      type="number"
                      placeholder="Cantidad"
                      value={ao.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                    </Col>
                    
                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="surtido"
                      type="number"
                      placeholder="surtido"
                      value={ao.surtido.toFixed(2)}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={ao.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={ao.total}
                      disabled
                    />
                  </Col>

                    </>
                  ) : (
                    <>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={ao.colores}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {ao.arrayColoresArticulo
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                  <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="clave"
                    type="text"
                    placeholder="Clave"
                    value={ao.clave}
                    required
                    disabled
                  />
                  </Col>

                  <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    placeholder="Cantidad"
                    value={ao.cantidad}
                    required
                    disabled
                  />
                  </Col>
                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="surtido"
                      type="number"
                      placeholder="surtido"
                      value={ao.surtido.toFixed(2)}
                      disabled
                    />
                  </Col>

                  {user.cambiar_precio ? (
                    <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={ao.precio}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>
                  ):(
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        disabled
                      />
                    </Col>
                  )}
                  

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={ao.descuento}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={ao.total}
                      disabled
                    />
                  </Col>
                  </>
                  )}

                  <Col md={1}>
                    <Baja
                      idStatus={ao.id}
                      is_active={ao.activo}
                      URL_BAJA={process.env.REACT_APP_URL_ARTICULOS_PEDIDO}
                    />
                  </Col>
                </Row>
              </div>
            ))}

            {/* Agregar mas articulos */}

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {articulos
                        .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.codigo} / {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="colores"
                      type="select"
                      value={inputField.colores}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="0">Selecciona</option>
                      {inputField.arrayColoresArticulo
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="clave"
                        type="text"
                        placeholder="Clave"
                        value={inputField.clave}
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                  {inputField.colores == "" ? (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  ) : (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  )}

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="surtido"
                      type="number"
                      placeholder="0"
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={inputField.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={6}>
                <h4 id="logoutBoton">
                  TOTALES {total_kilos_edit} kg. / {total_metros_edit} mts. /{" "}
                  {"$" +
                    new Intl.NumberFormat("en-US").format(total_general_edit)}
                </h4>
              </Col>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPedidos;
