import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function GastosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_PRESUPUESTOS = process.env.REACT_APP_URL_PRESUPUESTOS;

  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const URL_PERIODOS_GASTOS = process.env.REACT_APP_URL_PERIODOS_GASTOS;

  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [fecha, setFecha] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [disponible, setDisponible] = useState("");
  const [utilizado, setUtilizado] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [presupuestos, setPresupuestos] = useState([]);
  const [selectedPresupuesto, setSelectedPresupuesto] = useState("");
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [ciclos, setCiclos] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [URL, setURL] = useState("");
  const [nuevoDisponible, setNuevoDisponible] = useState("");
  const [nuevoUtilizado, setNuevoUtilizado] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setSelectedProveedor("");
    setSelectedPresupuesto("");
    setFecha("");
    setSubtotal("");
    setIva("");
    setTotal("");
    setDescripcion("");
    setURL("");
    setDisponible("");
    setUtilizado("");
    setNuevoDisponible("");
    setNuevoUtilizado("");
    setValidaBoton(true)
  };
  const clearSaldos = () => {
    setDisponible("");
    setUtilizado("");
    setNuevoDisponible("");
    setNuevoUtilizado("");
  };

  useEffect(() => {
    axios
      .get(URL_PRESUPUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPresupuestos = res.data;
        setPresupuestos(allPresupuestos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PERIODOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPeriodos = res.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [nuevoDisponible, nuevoUtilizado, iva, total]);



  const botonSeguir = (e) => {
    setSelectedPresupuesto(e.target.value);
    presupuestos.map((c) => {
      if (selectedPresupuesto == c._id) {
        setDisponible(c.disponible);
        setUtilizado(c.utilizado);
        setURL(`${URL_PRESUPUESTOS}/${c._id}`);
      }
    });
  };

  const calcula = (e) => {
    setSubtotal(e.target.value);
    setTotal(parseFloat(subtotal) + parseFloat(iva))
  };

  const calculaIVA = (e) => {
    setIva(e.target.value);
    setTotal(parseFloat(subtotal) + parseFloat(iva))
  };

  const saveGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    if (disponible >= total) {
      axios
        .all([
          axios.post(
            URL_GASTOS,
            {
              fecha,
              pagado: "No",
              subtotal,
              iva,
              total,
              descripcion,
              proveedores: selectedProveedor,
              presupuestos: selectedPresupuesto,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Crear Gasto',
              detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} / Proveedor: ${selectedProveedor} / Presupuesto: ${selectedPresupuesto}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
            clear();
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true)
        });
    } else {
      clearSaldos();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El importe excede el presupuesto establecido!",
      });
      setValidaBoton(true)
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastos_create  ?(
      <div className="container">
        <div className="card container col-sm-8">
          <h3 align="center">Dar de alta un Gasto</h3>
          <Form onSubmit={saveGasto}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col sm={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                Fecha
              </Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
                </Col>
                <Col sm={6}>
                <Label className="mr-sm-2">Proveedores</Label>
              <Input
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="0">Selecciona un Proveedor</option>
                {proveedores
                  .sort((a, b) => a.nombre_comercial > b.nombre_comercial ? 1 : -1)
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre_comercial} {a.razon_social}
                      </option>
                    );
                  })}
              </Input>
                </Col>
              </Row>
              <Label className="mr-sm-2">Presupuesto</Label>
              {/* <Input
                type="select"
                value={selectedPresupuesto}
                required
                onBlur={botonSeguir}
                onChange={botonSeguir}
              >
                <option value="0">Selecciona un Presupuesto</option>
                {presupuestos
                  .sort((a, b) => a.periodosGastos[0].fecha_inicio < b.periodosGastos[0].fecha_inicio ? 1 : -1)
                  .map((c) => {
                    if(c.is_active=="Si" && c.autorizado=="Si" && 
                    user.planteles == PLANTELES_GENERAL &&
                    user.areas == AREAS_GENERAL){
                    return (
                      <option value={c._id}>
                        {c.periodosGastos[0].name} {c.areas[0].name} {c.planteles[0].name} {c.departamentos[0].name}
                      </option>
                    )}else if(user.planteles == c.planteles[0]._id && user.areas == c.areas[0]._id){
                      return (
                        <option value={c._id}>
                        {c.periodosGastos[0].name} {c.areas[0].name} {c.planteles[0].name} {c.departamentos[0].name}
                      </option>
                      )}
                  })}
              </Input> */}
                <Row>
            {user.planteles == PLANTELES_GENERAL ? (
                    <Col md={4}>
                      <Label className="mr-sm-2">Planteles</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        value={selectedPlantel}
                        onChange={(e) => setSelectedPlantel(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {planteles
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            if (a._id != PLANTELES_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
             {user.areas == AREAS_GENERAL ? (
                    <Col md={4}>
                      <Label className="mr-sm-2">Areas</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
                  {/* <Col md={4}>
                      <Label className="mr-sm-2">Departamento</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedDepartamento}
                        onChange={(e) => setSelectedDepartamento(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {departamentos
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col> */}
                  </Row>

                  <Row>
                    <Col md={4}>
                    <Label className="mr-sm-2">Ciclo</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedCiclo}
                        onChange={(e) => setSelectedCiclo(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {ciclos
                          .sort((a, b) => (a.fecha_inicio < b.fecha_inicio ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>
                      <Col md={4}>
                    <Label className="mr-sm-2">Periodo</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedPeriodo}
                        onChange={(e) => setSelectedPeriodo(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {periodos
                          .sort((a, b) => (a.fecha_inicio < b.fecha_inicio ? 1 : -1))
                          .map((a) => {
                            if(selectedCiclo == a.ciclos[0]._id){
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                      <Col md={4}>
                    <Label className="mr-sm-2">Presupuesto</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedPresupuesto}
                        onBlur={botonSeguir}
                        onChange={botonSeguir}
                      >
                        <option value="0">Selecciona</option>
                        {presupuestos
                          .sort((a, b) => (a.departamentos[0].name > b.departamentos[0].name ? 1 : -1))
                          .map((a) => {
                            if(a.is_active=="Si" && a.autorizado=="Si" && 
                            user.planteles == PLANTELES_GENERAL &&
                            user.areas == AREAS_GENERAL &&
                            selectedPeriodo == a.periodosGastos[0]._id &&
                            selectedPlantel == a.planteles[0]._id &&
                            selectedArea == a.areas[0]._id
                            ){
                              return <option value={a._id}>{a.departamentos[0].name}</option>;
                            }else if(user.planteles == a.planteles[0]._id && user.areas == a.areas[0]._id && selectedPeriodo == a.periodosGastos[0]._id ){
                              return (
                                <option value={a._id}>{a.departamentos[0].name}</option>
                              )}
                          })}
                      </Input>
                      </Col>
                  </Row>

              <Label for="exampleEmail" className="mr-sm-2">
                Descripcion
              </Label>
              <Input
                type="textarea"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />

            <Row>   
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                Subtotal
              </Label>
              <Input
                type="number"
                placeholder="Subtotal"
                value={subtotal}
                required
                onBlur={calcula}
                onChange={calcula}
              />
               </Col>
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                IVA
              </Label>
              <Input
                type="number"
                placeholder="IVA"
                value={iva}
                required
                onBlur={calculaIVA}
                onChange={calculaIVA}
              />
               </Col>
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                Total
              </Label>
              <Input
                type="number"
                placeholder={total}
                disabled
              />
               </Col>
             </Row>
              
            </FormGroup>
            <br />
            <Col>
              <Row>
              {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/Gastos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Col>
          </Form>
        </div>
      </div>
      ): undefined } 
    </>
  );
}

export default GastosCreate;
